import { Rating } from '../types/enums/Rating';
import { AlertOption, RatingPreference } from '../types/email-preferences/EmailPreferences';
import { constants } from '../constants/constants';
import { Currency } from '../types/enums/Currency';
import { AlertEnabledState } from '../types/enums/AlertEnabledState';

const getDefaultRatingPreferences = (rating: Rating): RatingPreference => ({
    rating,
    isEUR: false,
    isUSD: false,
    minSize: constants.emailPreferencesSizeRange.min,
    maxSize: constants.emailPreferencesSizeRange.max
});

const isCurrencyActive = (ratingPreference: RatingPreference, currency: Currency) => {
    return (
        (currency === Currency.USD && ratingPreference.isUSD) ||
        (currency === Currency.EUR && ratingPreference.isEUR)
    );
}


const alertEnabledStateByAlertOption: { [key in AlertEnabledState]?: AlertOption } = {
    [AlertEnabledState.Enabled]: AlertOption.Instant,
    [AlertEnabledState.PartiallyEnabled]: AlertOption.Instant,
};

const convertAlertEnabledStateToAlertOption = (value: AlertEnabledState) => {
    return alertEnabledStateByAlertOption[value] ?? AlertOption.Never;
}

const alertOptionByPortfolioAlertEnabledState = {
    [AlertOption.Daily]: AlertEnabledState.Enabled,
    [AlertOption.Instant]: AlertEnabledState.Enabled,
    [AlertOption.Never]: AlertEnabledState.Disabled
}

const convertAlertOptionToAlertEnabledState = (value: AlertOption) => {
    return alertOptionByPortfolioAlertEnabledState[value];
}

export const emailPreferencesUtils = {
    getDefaultRatingPreferences,
    isCurrencyActive,
    convertAlertEnabledStateToAlertOption,
    convertAlertOptionToAlertEnabledState
};
